import Api from '@/servicesLocal/Api'
const path = 'images'

export default {    
    upload(data) {
        //types 1-wine / 2-menu / 3-main
        return Api().post(
            path + `/upload`,
            data, 
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
        )
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    }
}