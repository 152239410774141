import Api from '@/servicesLocal/Api'
const path = 'menuitem'

export default {
    index(type,name,description, page, limit,visible) {
        return Api().get(path, {
            params: {
                type,
                name,
                description,
                page,
                limit,
                visible
            }
        })
    },
    getGroups(type) {
        return Api().get('menuitemgroups', {
            params: {
                type
            }
        })
    },
    addUpdateGroup(group) {
        return Api().post('menuitemgroups', group);
    },
    show(id) {
        return Api().get(path + `/${id}`)
    },
    addUpdate(type) {
        return Api().post(path, type)
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    },
    deleteGroup(id) {
        return Api().delete('menuitemgroups' + `/${id}`)
    }
}