<template>
  <v-dialog v-model="show" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click="resetFilters">
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary" fixed>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Filtro"
          class="hidden-sm-and-down" @input="FilterItems()" v-model="filterWord"></v-text-field>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="CloseWindow()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text style="padding-top: 65px;">
        <v-container grid-list-md>
          <!-- <v-btn fixed dark fab bottom left color="pink" @click="removeAllDescs()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>-->
          <v-btn @click="CloseWindow()" fixed dark fab bottom right color="green">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-layout wrap>
            <v-flex v-for="item in filteredItems" :key="item.id" xs12 sm6 md4 lg3 xl3>
              <v-card :color="item.active ? 'blue lighten-1' : 'grey lighten-3'" class="d-flex align-center"
                @click="pickItem(item)" style="cursor: pointer;">
                <v-card-text>
                  <v-layout wrap>
                    <v-flex xs10 sm10 md10>
                      <p style="word-break: break-word;">{{ item.name }}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "Multi-Selector",
  props: ["title", "fullItems", "existingItems"],
  data() {
    return {
      currentItem: {},
      dialog: false,
      show: false,
      filteredItems: [],
      filterWord: ""
    };
  },
  methods: {
    resetFilters() {
      this.fullItems.forEach(item => {
        if (_.find(this.existingItems, ["id", item.id])) {
          item.active = true;
        } else {
          item.active = false;
        }
      });

      this.filterWord = "";
      this.FilterItems();
    },
    FilterItems() {
      this.filteredItems = _.filter(this.fullItems, item => {
        return (
          item.name
            .toLowerCase()
            .indexOf(this.filterWord.toLowerCase()) > -1
        );
      });
    },
    pickItem(item) {
      //   try {
      let copy;
      if (!_.find(this.existingItems, ["id", item.id])) {
        item.active = true;
        copy = _.clone(item, true);
        this.existingItems.push(copy);
      } else {
        item.active = false;
        let index = _.findIndex(this.existingItems, ["id", item.id]);
        this.existingItems.splice(index, 1);
      }
      //   } catch (error) {
      //   }
    },
    CloseWindow() {
      this.show = !this.show;
    }
  },
  mounted() { }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
