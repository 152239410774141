import Api from '@/servicesLocal/Api'
const path = 'wines'

export default {
    index(name, year, region, type, winery, page, limit, visible, spotlight) {
        return Api().get(path, {
            params: {
                name,
                year,
                region,
                type,
                winery,
                page,
                limit,
                visible,
                spotlight
            }
        })
    },
    show(id) {
        return Api().get(path + `/show/${id}`);
    },
    showByExternal(id) {
        return Api().get(path + `/showByExternal/${id}`);
    },
    addUpdateImport(wine) {
        return Api().post(path + "/import", wine);
    },
    addUpdate(wine) {
        return Api().post(path, wine);
    },
    uploadImgs(id, data) {
        return Api().post(
            path + `/uploadimgs/${id}`,
            data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        )
    },
    deleteImg(id) {
        return Api().delete(path + `/deleteimg/${id}`)
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    },
    getAllIds() {
        return Api().get(path + `/getallids`)
    }
}