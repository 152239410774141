var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.$route.path != '/login')?_c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
        name: 'apikeys-list'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-key-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Chaves API")])],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'wines-list-import'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-database-import")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Importar Vinhos")])],1)],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-bottle-wine-outline","no-action":"","value":false}},[_c('v-list-item',{staticStyle:{"padding":"0px"},attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-title',[_vm._v("Os Meus Vinhos")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'wines-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Vinhos ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'types-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Tipos ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'wineries-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Vinicolas ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'regions-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Regiões ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'castes-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Castas ")],1),_c('v-list-item-icon')],1)],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-food-steak","no-action":"","value":false}},[_c('v-list-item',{staticStyle:{"padding":"0px"},attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-title',[_vm._v("Comida")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'maincourses-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Items ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'foods-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Grupos ")],1),_c('v-list-item-icon')],1)],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-glass-mug-variant","no-action":"","value":false}},[_c('v-list-item',{staticStyle:{"padding":"0px"},attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-title',[_vm._v("Bebidas")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'beverages-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Items ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'beverages-groups'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Grupos ")],1),_c('v-list-item-icon')],1)],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-food","no-action":"","value":false}},[_c('v-list-item',{staticStyle:{"padding":"0px"},attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-title',[_vm._v("Entradas")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'entrees-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Items ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'entrees-groups'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Grupos ")],1),_c('v-list-item-icon')],1)],1),_c('v-list-group',{attrs:{"prepend-icon":"mdi-cupcake","no-action":"","value":false}},[_c('v-list-item',{staticStyle:{"padding":"0px"},attrs:{"slot":"activator"},slot:"activator"},[_c('v-list-item-title',[_vm._v("Sobremesas")])],1),_c('v-list-item',{attrs:{"to":{
          name: 'desserts-list'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Items ")],1),_c('v-list-item-icon')],1),_c('v-list-item',{attrs:{"to":{
          name: 'desserts-groups'
        }}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-chevron-right")]),_vm._v("Grupos ")],1),_c('v-list-item-icon')],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'user'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Password")])],1)],1),(!_vm.$store.state.isUserLoggedIn)?_c('v-list-item',{attrs:{"to":{
        name: 'login'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Login")])],1)],1):_vm._e(),(_vm.$store.state.isUserLoggedIn)?_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"blue darken-3","dark":""}},[_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[(_vm.$route.path != '/login')?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("AppyWine")])],1),_c('v-spacer'),(_vm.user)?_c('p',{staticClass:"ma-2"},[_vm._v(_vm._s(_vm.user.name))]):_vm._e(),_c('v-btn',{attrs:{"icon":"","large":""}},[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('img',{attrs:{"src":require("@/assets/infordio.png"),"alt":"Infordio"}})])],1)],1),_c('v-main',[_c('router-view'),_c('v-snackbar',{attrs:{"timeout":6000,"color":_vm.snackbar.color},model:{value:(_vm.snackbar.showing),callback:function ($$v) {_vm.$set(_vm.snackbar, "showing", $$v)},expression:"snackbar.showing"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar.showing = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }