<template>
  <menu-item-abs type="beverage"/>
</template>

<script>
import MenuItemAbs from "./MenuItemAbs";
export default {
  name: "Beverages-List",
  components: {
    MenuItemAbs
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
