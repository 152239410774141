import Api from '@/services/Api'
const path = 'foods'

export default {
    index(name, page) {
        return Api().get(path, {
            params: {
                name,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    }
}