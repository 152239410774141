<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="primary" class="mb-2" @click="openNewKeyDialog">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field v-model="editedItem.key" :rules="[rules.required]" append-icon="mdi-refresh" readonly
                    @click:append="generateNewKey()" label="Chave"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :disabled="savingInfo" @click="close">Cancelar</v-btn>
          <v-btn color="blue darken-1" text :loading="savingInfo" @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="600"
          :headers="headers" :items="items" :loading="loading" hide-default-footer
          :items-per-page.sync="items.length + 10" class="elevation-1">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
          <template v-slot:item.discontinued="{ item }">
            <v-icon v-if="item.discontinued" color="green darken-2">done</v-icon>
            <v-icon v-else color="red darken-2">clear</v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <td class="justify-center layout px-0">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="editItem(item)">
                    <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceApiKeys from "@/servicesLocal/ServiceApiKeys";
export default {
  name: "ApiKeys-List",
  directives: {
    infiniteScroll
  },
  data: () => ({
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Chave", value: "key" },
      { text: "Actions", value: "action", sortable: false, align: "center" }
    ],
    items: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    editedItem: {
      key: "",
      id: null
    },
    defaultItem: {
      key: "",
      id: null
    },
    rules: {
      required: value => !!value || "Campo Obrigatório."
    }
  }),
  methods: {
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceApiKeys.index(this.page);

        this.items = this.items.concat(response.data);

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    openNewKeyDialog() {
      this.generateNewKey();
      this.dialog = true;
    },
    generateNewKey() {
      this.editedItem.key = "";
      var characters =
        "abcdefghijkmnopqrstuvwxyz123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 12; i++) {
        this.editedItem.key += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      this.editedItem.key = [
        this.editedItem.key.slice(0, 4),
        "-",
        this.editedItem.key.slice(4)
      ].join("");
      this.editedItem.key = [
        this.editedItem.key.slice(0, 9),
        "-",
        this.editedItem.key.slice(9)
      ].join("");
    },
    async deleteItem(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceApiKeys.delete(item.id);
          let index = this.items.indexOf(item);

          if (index != -1) {
            this.items.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.savingInfo = true;

        try {
          const response = await ServiceApiKeys.addUpdate(this.editedItem);
          if (this.editedIndex > -1) {
            Object.assign(this.items[this.editedIndex], response.data);
          } else {
            this.items.push(response.data);
          }

          this.savingInfo = false;
          this.close();
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
          this.savingInfo = false;
        }
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nova Chave" : "Editar Chave";
    }
  }
};
</script>
