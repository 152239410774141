import Api from '@/servicesLocal/Api'
const path = 'users'

export default {
    update(credentials) {
        return Api().post(path + `/update`, credentials);
    },
    login(userName, pass) {
        return Api().get(path + `/login`, {
            params: {
                userName: userName,
                password: pass
            }
        })
    }
}