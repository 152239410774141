<template>
  <v-layout justify-center>
    <v-container class="grey lighten-5">
      <v-row justify="center">
        <v-col xs="12" md="6">
          <v-text-field
            v-model="newPass"
            label="Nova Password"
            outlined
            required
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col xs="12" md="6">
          <v-text-field
            v-model="confirmPass"
            label="Confirmação Password"
            outlined
            required
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col xs="12" md="6">
          <v-btn elevation="2" color="primary" outlined raised rounded @click="save()">Guardar</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-layout>
</template>

<script>
import ServiceUsers from "@/servicesLocal/ServiceUsers";
export default {
  name: "user-edit",
  data: () => ({
    show1: false,
    show2: false,
    overlay: false,
    newPass: "",
    confirmPass: ""
  }),
  methods: {
    async save() {
      try {
        if (!this.newPass || !this.confirmPass) {
          alert("Campos Nova Password e Confirmação são obrigatórios");
          return;
        }

        if (this.newPass !== this.confirmPass) {
          alert("Nova Password e Confirmação têm de ser iguais");
          return;
        }

        this.overlay = true;
        await ServiceUsers.update({
          password: this.newPass
        });
        this.$store.dispatch("setSnackbar", {});

        this.overlay = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    }
  }
};
</script>
