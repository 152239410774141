<template>
  <v-dialog v-model="dialog" scrollable persistent transition="dialog-bottom-transition">
    <v-card class="mx-auto">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar class="flex-grow-0" dark color="primary">
        <v-toolbar-title>Vinho</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" lg="8">
                <v-text-field
                  v-model="editedItem.name"
                  :counter="250"
                  :rules="[rules.required,rules.counter]"
                  label="Nome"
                  required
                  disabled
                ></v-text-field>

                <v-textarea v-model="editedItem.description" disabled rows="11" label="Descrição"></v-textarea>
              </v-col>

              <v-col cols="12" lg="2">
                <v-card height="300">
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Imagem Principal</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <input
                      v-show="false"
                      accept=".png, .jpg, .jpeg"
                      ref="inputUpload"
                      id="inputUpload"
                      type="file"
                      @change="AddInitFile()"
                    />
                    <v-card v-show="mainPics.length != 0" width="200" style="margin: 0 auto;">
                      <v-img
                        style="cursor: pointer !important;"
                        :src="mainPics[0] ? mainPics[0].path : ''"
                        contain
                        @click="handleGalery(mainPics,1)"
                        width="200"
                        height="200"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      />
                    </v-card>
                    <gallery
                      ref="gallery"
                      :index="imgIndex"
                      :images="auxImgs"
                      @close="imgIndex = null"
                    ></gallery>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" sm="6" lg="2">
                <v-text-field
                  v-model="editedItem.capacity"
                  disabled
                  label="Capacidade"
                  prefix="L"
                  type="number"
                  :rules="[rules.isValidFloat,rules.zero]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field
                  v-model="editedItem.temperature"
                  label="Temperatura"
                  :rules="[rules.isValidFloat]"
                  prefix="ºC"
                  type="number"
                  disabled
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field
                  v-model="editedItem.volume"
                  disabled
                  label="Volume"
                  prefix="%"
                  type="number"
                  :rules="[rules.isValidFloat,rules.zero,rules.hundred]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field
                  v-model="editedItem.year"
                  disabled
                  label="Ano"
                  type="number"
                  :rules="[rules.isValidInt,rules.zero]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field
                  v-model="editedItem.price"
                  disabled
                  label="Preço"
                  prefix="€"
                  type="number"
                  :rules="[rules.isValidFloat,rules.zero]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Imagens</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text>
                    <input
                      v-show="false"
                      accept=".png, .jpg, .jpeg"
                      ref="inputUploadMulti"
                      id="inputUploadMulti"
                      type="file"
                      multiple
                      @change="AddMultiFile()"
                    />

                    <div
                      v-show="images.length != 0"
                      ref="picsDiv"
                      style="height:250px; 
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;"
                    >
                      <ul class="ulPics" ref="picsUl">
                        <li v-for="(c,i) in images" :key="i">
                          <v-card>
                            <v-img
                              style="cursor: pointer !important;"
                              :src="c.path"
                              contain
                              @click="handleGalery(images,i)"
                              width="200"
                              height="200"
                              aspect-ratio="1"
                              class="grey lighten-2"
                            />
                          </v-card>
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <!-- <v-card-title>
              Castas
              <v-spacer></v-spacer>
              <caste-selector :fullItems="castes" :existingItems="editedItem.Castes"></caste-selector>
                  </v-card-title>-->

                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Castas</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <!--
            <v-data-table
    :headers="headers"
    :items="currCastes"
    class="elevation-1"
                  />-->
                  <ul>
                    <li v-for="(c,i) in editedItem.Castes" :key="i">{{c.name}}</li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <!-- <v-card-title>
              Castas
              <v-spacer></v-spacer>
              <caste-selector :fullItems="castes" :existingItems="editedItem.Castes"></caste-selector>
                  </v-card-title>-->

                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Comida</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <!--
            <v-data-table
    :headers="headers"
    :items="currCastes"
    class="elevation-1"
                  />-->
                  <ul>
                    <li v-for="(c,i) in editedItem.Food" :key="i">{{c.name}}</li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
                    <v-card-title class="headline">Prémios</v-card-title>
                  </v-toolbar>
                  <v-data-table
                    :headers="headers"
                    :items="editedItem.Awards"
                    hide-default-footer
                    class="elevation-1"
                  ></v-data-table>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-text-field v-model="editedItem.Region.name" label="Região" required disabled></v-text-field>

                <v-text-field v-model="editedItem.Type.description" label="Tipo" required disabled></v-text-field>

                <v-text-field v-model="editedItem.Winery.name" label="Vinicola" required disabled></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="save(true)">Importar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import VueGallery from "vue-gallery";

export default {
  name: "CreateUpdateWine",
  props: ["regions", "types", "wineries", "overlay", "castes"],
  components: {
    gallery: VueGallery
  },
  data: () => ({
    dialog: false,
    valid: true,
    filterDialog: false,
    fab: false,
    page: 1,
    items: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    description: null,
    editedItem: {
      name: "",
      description: "",
      capacity: "",
      price: "",
      temperature: "",
      volume: "",
      year: "",
      TypeId: null,
      WineryId: null,
      RegionId: null,
      Region: { name: "" },
      Type: { description: "" },
      Winery: { name: "" },
      Castes: [],
      id: null
    },
    defaultItem: {
      name: "",
      description: "",
      capacity: "",
      price: "",
      temperature: "",
      volume: "",
      year: "",
      Region: { name: "" },
      Type: { description: "" },
      Winery: { name: "" },
      TypeId: null,
      WineryId: null,
      RegionId: null,
      Castes: [],
      id: null
    },
    headers: [
      {
        text: "Descrição",
        align: "start",
        sortable: true,
        value: "description"
      }
    ],
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      isValidInt: value =>
        /(?<=\s|^)\d+(?=\s|$)/.test(value) || "Valor inválido",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      zero: v => v >= 0 || "Valor tem de ser >= 0",
      hundred: v => v <= 100 || "Valor tem de ser <= 100"
    },
    returnIndex: -1,
    imgIndex: -1,
    mainPics: [],
    images: [],
    auxImgs: []
  }),
  methods: {
    async open(wine, index) {
      this.returnIndex = index;
      if (wine) {
        this.editedItem = Object.assign({}, wine);
        this.mainPics = _.filter(wine.Images, ["is_main", true]);
        this.images = _.filter(wine.Images, ["is_main", false]);
      }

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.mainPics = [];
        this.images = [];
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.Castes = [];
        this.returnIndex = -1;
      });
    },
    save(close) {
      if (this.$refs.form.validate()) {
        this.$emit("saveChanges", this.editedItem, close);
      }
    },
    handleGalery(srcArray, index) {
      this.auxImgs = [];
      _.each(srcArray, item => {
        this.auxImgs.push(item.path);
      });
      this.imgIndex = index;
    },
    deletePhoto(srcArray, index) {
      this.$emit("deletePhoto", srcArray, index);
    }
  }
};
</script>

<style>
.ulPics {
  height: 100%;
  list-style-type: none;
  padding: 10px;
  display: -webkit-inline-box;
}

ul li {
  padding: 10px;
  height: 100%;
}

li img {
  height: 100%;
  width: 300px;
  cursor: pointer;
}
.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
  cursor: pointer !important;
}
.v-input--is-disabled input {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
}

.v-input--is-disabled textarea {
  color: rgb(0, 0, 0) !important;
  opacity: 1;
}
</style>
