import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations';
import Cookies from 'js-cookie';
Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => Cookies.get(key),
                setItem: (key, value) => Cookies.set(key, value, {
                    secure: false
                }),
                removeItem: key => Cookies.remove(key)
            }
        }),
        sharedMutations({
            predicate: ['setToken', 'setUser', 'setLogTime', 'setSnackBar']
        })
    ],
    state: {
        token: null,
        logTime: null,
        user: null,
        isUserLoggedIn: false,
        menu: false,
        beverages: false,
        entrees: false,
        desserts: false,
        snackbar: {
            showing: false,
            color: 'success'
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            state.isUserLoggedIn = !!(token)
        },
        setUser(state, user) {
            state.user = user
        },
        setMenu(state, allow) {
            state.menu = allow
        },
        setBeverages(state, allow) {
            state.beverages = allow
        },
        setEntrees(state, allow) {
            state.entrees = allow
        },
        setDesserts(state, allow) {
            state.desserts = allow
        },
        setLogTime(state, logTime) {
            state.logTime = logTime
        },
        setSnackBar(state, snackbar) {
            state.snackbar = snackbar
        }
    },
    actions: {
        setToken({
            commit
        }, token) {
            commit('setToken', token)
        },
        setUser({
            commit
        }, user) {
            commit('setUser', user)
        },
        setMenu({
            commit
        }, allow) {
            commit('setMenu', allow)
        },

        setBeverages({
            commit
        }, allow) {
            commit('setBeverages', allow)
        },
        
        setEntrees({
            commit
        }, allow) {
            commit('setEntrees', allow)
        },
        
        setDesserts({
            commit
        }, allow) {
            commit('setDesserts', allow)
        },

        setLogTime({
            commit
        }, logTime) {
            commit('setLogTime', logTime)
        },
        setSnackbar({
            commit
        }, snackbar) {
            if (typeof snackbar.showing == "undefined") {
                snackbar.showing = true;
            }
            snackbar.text = snackbar.text || 'Operação efectuada com sucesso.';
            snackbar.color = snackbar.color || 'success';
            commit('setSnackBar', snackbar);
        }
    }
})