<template>
  <menu-item-groups-abs type="beverage"/>
</template>

<script>
import MenuItemGroupsAbs from "./MenuItemGroupsAbs";
export default {
  name: "Beverage-Groups",
  components: {
    MenuItemGroupsAbs
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
