import axios from 'axios';

export default () => {
    return axios.create({
        baseURL: process.env.VUE_APP_MAIN_API_URL + '/api/',
        headers: {
            Authorization: `Key ${process.env.VUE_APP_MAIN_API_KEY}`
        }
    })
}
