<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Autenticação</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="doLogin()">
              <v-text-field
                :rules="[rules.required]"
                prepend-icon="mdi-account"
                name="login"
                label="Utilizador"
                type="text"
                v-model="userName"
              ></v-text-field>
              <v-text-field
                :rules="[rules.required]"
                id="password"
                prepend-icon="mdi-lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loading" @click="doLogin()">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceUsers from "@/servicesLocal/ServiceUsers";
export default {
  name: "Login",

  data: () => ({
    valid: true,
    loading: false,
    userName: "",
    password: "",
    rules: {
      required: value => !!value || "Campo Obrigatório."
    }
  }),
  methods: {
    async doLogin() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const response = await ServiceUsers.login(
            this.userName,
            this.password
          );

          this.$store.dispatch("setToken", response.data.token);
          this.$store.dispatch("setUser", response.data.user);
          this.$store.dispatch("setLogTime", response.data.logTime);
          
          this.$store.dispatch("setMenu", response.data.allowMenu);
          this.$store.dispatch("setBeverages", response.data.allowBeverages);
          this.$store.dispatch("setEntrees", response.data.allowEntrees);
          this.$store.dispatch("setDesserts", response.data.allowDesserts);

          this.loading = false;

          this.$router.push(this.$route.query.redirect || "/");
        }
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Login Inválido"
        });
      }
    }
  }
};
</script>
