<template>
  <v-dialog v-model="dialog" scrollable persistent transition="dialog-bottom-transition">
    <v-card class="mx-auto">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-toolbar class="flex-grow-0" dark color="primary">
        <v-toolbar-title>Vinho</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>


      <v-dialog v-model="dialogAward" max-width="600px" scrollable persistent transition="dialog-bottom-transition">
        <v-card>
          <v-card-title>
            <span class="headline">{{ awardFormTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="formAward" v-model="validAward">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field @keydown.enter="saveAward" ref="awardDesc" v-model="editedAward.description"
                      :counter="250" :rules="[rules.required, rules.counter]" label="Descrição"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeAwardForm">Fechar</v-btn>
            <v-btn color="blue darken-1" text @click="saveAward">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" lg="5">
                <v-switch v-model="editedItem.visible" class="ma-2" label="Visivél"></v-switch>
              </v-col>
              <v-col cols="12" lg="5">
                <v-switch v-model="editedItem.spotlight" class="ma-2" label="Destaque"></v-switch>
              </v-col>
              <v-col cols="12" lg="8">
                <v-text-field v-model="editedItem.name" :counter="250" :rules="[rules.required, rules.counter]"
                  label="Nome" required></v-text-field>

                <v-textarea v-model="editedItem.description" rows="11" label="Descrição"></v-textarea>
              </v-col>

              <v-col cols="12" lg="2">
                <file-upload ref="fileUpload" :main="1" @spliceDeleted="spliceDeleted" />
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" sm="6" lg="2">
                <v-text-field v-model="editedItem.capacity" label="Capacidade" prefix="L"
                  :rules="[rules.isValidFloat]"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field v-model="editedItem.temperature" label="Temperatura" :counter="250"
                  :rules="[rules.required, rules.counter]"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field v-model="editedItem.volume" label="Volume" prefix="%" type="number"
                  :rules="[rules.isValidFloat, rules.zero, rules.hundred]"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field v-model="editedItem.year" label="Ano" type="number"
                  :rules="[rules.isValidInt, rules.zero]"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" lg="2">
                <v-text-field v-model="editedItem.price" label="Preço" prefix="€" type="number"
                  :rules="[rules.isValidFloat, rules.zero]"></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <file-upload ref="fileUploadMulti" :main="0" @spliceDeleted="spliceDeleted" />
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Castas</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="refresh">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <multi-selector :title="'Castas'" :fullItems="castes"
                      :existingItems="editedItem.Castes"></multi-selector>

                  </v-toolbar>
                  <ul>
                    <li v-for="(c, i) in editedItem.Castes" :key="i">{{ c.name }}</li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">

                <v-card>
                  <v-toolbar dark color="primary">
                    <v-toolbar-title>Comida</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="refresh">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <multi-selector :title="'Comida'" :fullItems="food"
                      :existingItems="editedItem.Food"></multi-selector>

                  </v-toolbar>
                  <ul>
                    <li v-for="(c, i) in editedItem.Food" :key="i">{{ c.name }}</li>
                  </ul>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">
                <v-card>
                  <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
                    <v-card-title class="headline">Prémios</v-card-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon rounded @click.stop="addUpdateAward()">
                            <v-icon v-on="on">mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar</span>
                      </v-tooltip>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-data-table :headers="headers" :items="editedItem.Awards" hide-default-footer
                    :items-per-page.sync="editedItem.Awards.length + 10" class="elevation-1">
                    <template v-slot:item.action="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" @click.stop="addUpdateAward(item)">
                            <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon class="mx-0" @click.stop="removeAward(item)">
                            <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col cols="12" lg="10">

                <v-autocomplete v-model="editedItem.RegionId" :items="regions" item-text="name" item-value="id"
                  label="Região" :rules="[rules.required]" clearable><template v-slot:append-outer>
                    <v-icon @click="refresh">mdi-refresh</v-icon>
                  </template></v-autocomplete>

                <v-autocomplete v-model="editedItem.TypeId" :items="types" item-text="description" item-value="id"
                  label="Tipo" :rules="[rules.required]" clearable><template v-slot:append-outer>
                    <v-icon @click="refresh">mdi-refresh</v-icon>
                  </template></v-autocomplete>

                <v-autocomplete v-model="editedItem.WineryId" :items="wineries" item-text="name" item-value="id"
                  label="Vinicola" :rules="[rules.required]" clearable><template v-slot:append-outer>
                    <v-icon @click="refresh">mdi-refresh</v-icon>
                  </template></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue darken-1" text @click="save(false)">Guardar e Adicionar Outro</v-btn> -->
        <v-btn color="blue darken-1" text @click="save(false)">Guardar</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import _ from "lodash";
import MultiSelector from "../common/MultiSelector";
import FileUpload from "../common/FileUpload";
import ServiceWines from "@/servicesLocal/ServiceWines";

export default {
  name: "CreateUpdateWine",
  props: ["regions", "types", "wineries", "castes", "food"],
  components: {
    MultiSelector,
    FileUpload
  },
  data: () => ({
    overlay: false,
    dialog: false,
    valid: true,
    validAward: true,
    filterDialog: false,
    fab: false,
    page: 1,
    items: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    description: null,
    editedItem: {
      name: "",
      description: "",
      capacity: "",
      price: "",
      temperature: "",
      volume: "",
      year: "",
      TypeId: null,
      WineryId: null,
      RegionId: null,
      Awards: [],
      Food: [],
      Castes: [],
      Images: [],
      id: null
    },
    defaultItem: {
      name: "",
      description: "",
      capacity: "",
      price: "",
      temperature: "",
      volume: "",
      year: "",
      TypeId: null,
      WineryId: null,
      RegionId: null,
      Awards: [],
      Food: [],
      Castes: [],
      Images: [],
      id: null
    }, headers: [
      {
        text: "Descrição",
        align: "start",
        sortable: true,
        value: "description"
      },
      { text: "Actions", value: "action", sortable: false, align: "end" }
    ],
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      isValidInt: value =>
        /(?<=\s|^)\d+(?=\s|$)/.test(value) || "Valor inválido",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      zero: v => v >= 0 || "Valor tem de ser >= 0",
      hundred: v => v <= 100 || "Valor tem de ser <= 100"
    },
    returnIndex: -1,
    imgIndex: -1,
    auxImgs: [],
    dialogAward: false,
    defaultAward: {
      id: null,
      description: null
    },
    editedAward: {
      id: null,
      description: null
    },
  }),
  methods: {
    async open(wine, index) {
      this.returnIndex = index;
      if (wine) {
        this.$nextTick(() => {
          this.$refs.fileUpload.setExistingImages(
            _.filter(wine.Images, ["is_main", true])
          );
          this.$refs.fileUploadMulti.setExistingImages(
            _.filter(wine.Images, ["is_main", false])
          );
        });

        this.editedItem = Object.assign({}, wine);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);

        this.$nextTick(() => {
          this.$refs.fileUpload.setExistingImages([]);
          this.$refs.fileUploadMulti.setExistingImages([]);
        });
      }

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages([]);
        this.$refs.fileUploadMulti.setExistingImages([]);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.Castes = [];
        this.returnIndex = -1;
      });
    },
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages([]);
        this.$refs.fileUploadMulti.setExistingImages([]);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.Castes = [];
        this.returnIndex = -1;
      });
    },
    async save(close) {
      if (this.$refs.form.validate()) {
        this.overlay = true;

        try {
          const response = await ServiceWines.addUpdate(this.editedItem);

          let errorMsg = "";
          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUpload.uploadImgs(response.data.id, 1)
            );
            this.$refs.fileUpload.setExistingImages(
              _.filter(response.data.Images, ["is_main", true])
            );
          } catch (error) {
            errorMsg = "Erro ao adicionar a imagem principal ";
          }

          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUploadMulti.uploadImgs(response.data.id, 1)
            );
            this.$refs.fileUploadMulti.setExistingImages(
              _.filter(response.data.Images, ["is_main", false])
            );
          } catch (error) {
            errorMsg = errorMsg + "Erro ao adicionar imagens";
          }

          this.$emit("updateWineList", response.data, this.returnIndex);

          this.overlay = false;

          if (errorMsg.length != 0) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: errorMsg
            });
          } else {
            this.$store.dispatch("setSnackbar", {});
          }

          this.dialog = close;
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
          this.overlay = false;
        }
      }
    },
    removeAward(item) {
      this.editedItem.Awards.splice(this.editedItem.Awards.indexOf(item), 1);
    },
    addUpdateAward(item) {
      if (item) {
        this.editedAwardIndex = this.editedItem.Awards.indexOf(item);
        this.editedAward = Object.assign({}, item);
      } else {
        this.editedAward = Object.assign({}, this.defaultAward);
      }

      this.dialogAward = true;
      requestAnimationFrame(() => {
        return this.$refs.awardDesc.$refs.input.focus()
      });
    },
    closeAwardForm() {
      this.dialogAward = false;
      this.$refs.formAward.reset();
      this.$refs.formAward.resetValidation();
      this.$nextTick(() => {
        this.editedAward = Object.assign({}, this.defaultAward);
        this.editedAwardIndex = -1;
      });
    },
    saveAward() {
      if (this.$refs.formAward.validate()) {
        if (this.editedAwardIndex > -1) {
          Object.assign(
            this.editedItem.Awards[this.editedAwardIndex],
            this.editedAward
          );
        } else {
          this.editedItem.Awards.push(Object.assign({}, this.editedAward));
        }
        this.closeAwardForm();
      }
    },
    spliceDeleted(id) {
      let index = 0;

      _.each(this.editedItem.Images, item => {
        if (item.id === id) {
          return;
        } else {
          index += 1;
        }
      });

      this.editedItem.Images.splice(index, 1);
      this.$emit("updateWineList", this.editedItem, this.returnIndex);
    },
    refresh() {
      this.$emit("refresh");
    }
  },
  computed: {
    awardFormTitle() {
      return this.editedAwardIndex === -1 ? "Novo Prémio" : "Editar Prémio";
    }
  }
};
</script>

<style scoped>
.ulPics {
  height: 100%;
  list-style-type: none;
  padding: 10px;
  display: -webkit-inline-box;
}

ul li {
  padding: 10px;
  height: 100%;
}

li img {
  height: 100%;
  width: 300px;
  cursor: pointer;
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
  cursor: pointer !important;
}
</style>
