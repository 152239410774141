import Api from '@/servicesLocal/Api'
const path = 'castes'

export default {
    index(name,description, page) {
        return Api().get(path, {
            params: {
                name,
                description,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    },
    addUpdate(type) {
        return Api().post(path, type)
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    }
}