import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index'
import vuetify from './plugins/vuetify';
import { sync } from 'vuex-router-sync'
import store from '@/store/store'
import VImageInput from 'vuetify-image-input/a-la-carte';
import money from "v-money";

Vue.component('VImageInput', VImageInput);

Vue.use(money, { precision: 4 });

sync(store, router);
// eslint-disable-next-line no-console
console.log(process.env.API_URL);
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');