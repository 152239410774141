import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'
import store from '@/store/store'
import Login from '@/components/Login'
import Denied from '@/components/Denied'

import ListApiKeys from '@/components/apiKeys/ListKeys'
import ListWines from '@/components/wines/ListWines'
import ListWinesCopy from '@/components/winesMain/ListWinesCopy'
import ListBeverages from '@/components/menuItems/ListBeverages'
import ListEntrees from '@/components/menuItems/ListEntrees'
import ListDesserts from '@/components/menuItems/ListDesserts'
import GroupsDesserts from '@/components/menuItems/GroupsDesserts'
import GroupsBeverages from '@/components/menuItems/GroupsBeverages'
import GroupsEntrees from '@/components/menuItems/GroupsEntrees'
import ListMainCourses from '@/components/mainCourses/ListMainCourses'
import ListTypes from '@/components/types/ListTypes'
import ListWineries from '@/components/wineries/ListWineries'
import ListRegions from '@/components/regions/ListRegions'
import ListCastes from '@/components/castes/ListCastes'
import ListFoods from '@/components/foods/ListFoods'
import EditUser from '@/components/user/EditUser'

Vue.use(VueRouter);

export let router = new Router({
    routes: [{
        path: '/',
        name: 'empty-path',
        component: ListWines,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },
    {
        path: '/denied',
        name: 'denied',
        component: Denied,
        meta: {
            auth: false
        }
    },
    {
        path: '/apikeys',
        name: 'apikeys-list',
        component: ListApiKeys,
        meta: {
            auth: true
        }
    },
    {
        path: '/wines',
        name: 'wines-list',
        component: ListWines,
        meta: {
            auth: true
        }
    },
    {
        path: '/winesimport',
        name: 'wines-list-import',
        component: ListWinesCopy,
        meta: {
            auth: true
        }
    },
    {
        path: '/beverages',
        name: 'beverages-list',
        component: ListBeverages,
        meta: {
            auth: true,
            beverages: true
        }
    },
    {
        path: '/beverages-groups',
        name: 'beverages-groups',
        component: GroupsBeverages,
        meta: {
            auth: true,
            beverages: true
        }
    },
    {
        path: '/entrees',
        name: 'entrees-list',
        component: ListEntrees,
        meta: {
            auth: true,
            entrees: true
        }
    },
    {
        path: '/entrees-groups',
        name: 'entrees-groups',
        component: GroupsEntrees,
        meta: {
            auth: true,
            entrees: true
        }
    },
    {
        path: '/maincourses',
        name: 'maincourses-list',
        component: ListMainCourses,
        meta: {
            auth: true,
            menu: true
        }
    },
    {
        path: '/desserts',
        name: 'desserts-list',
        component: ListDesserts,
        meta: {
            auth: true,
            desserts: true
        }
    },
    {
        path: '/desserts-groups',
        name: 'desserts-groups',
        component: GroupsDesserts,
        meta: {
            auth: true,
            desserts: true
        }
    },
    {
        path: '/user',
        name: 'user',
        component: EditUser,
        meta: {
            auth: true,
            menu: false
        }
    },
    {
        path: '/types',
        name: 'types-list',
        component: ListTypes,
        meta: {
            auth: true
        }
    },
    {
        path: '/wineries',
        name: 'wineries-list',
        component: ListWineries,
        meta: {
            auth: true
        }
    },
    {
        path: '/regions',
        name: 'regions-list',
        component: ListRegions,
        meta: {
            auth: true
        }
    },
    {
        path: '/castes',
        name: 'castes-list',
        component: ListCastes,
        meta: {
            auth: true
        }
    },
    {
        path: '/foods',
        name: 'foods-list',
        component: ListFoods,
        meta: {
            auth: true
        }
    },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.state.isUserLoggedIn) {
        next({
            path: '/login',
            query: {
                redirect: to.fullPath
            }
        });
    } else {
        if (to.meta.auth && store.state.logTime == null) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            if (to.meta.auth && (Date.now() - store.state.logTime >= 43200000)) {
                next({
                    path: '/login',
                    query: {
                        redirect: to.fullPath
                    }
                });
            } else if (to.meta.menu) {
                if (store.state.menu) {
                    next();
                } else {
                    next({
                        path: '/Denied',
                        query: {
                            redirect: to.fullPath
                        }
                    });
                }
            } else if (to.meta.beverages) {
                if (store.state.beverages) {
                    next();
                } else {
                    next({
                        path: '/Denied',
                        query: {
                            redirect: to.fullPath
                        }
                    });
                }
            } else if (to.meta.entrees) {
                if (store.state.entrees) {
                    next();
                } else {
                    next({
                        path: '/Denied',
                        query: {
                            redirect: to.fullPath
                        }
                    });
                }
            } else if (to.meta.desserts) {
                if (store.state.desserts) {
                    next();
                } else {
                    next({
                        path: '/Denied',
                        query: {
                            redirect: to.fullPath
                        }
                    });
                }
            } else {
                next();
            }

        }
    }
})

export default router