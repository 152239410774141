import Api from '@/servicesLocal/Api'
const path = 'foods'

export default {
    index(name, page) {
        return Api().get(path, {
            params: {
                name,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    },
    addUpdate(data) {
        return Api().post(path, data)
    },
    uploadImg(data) {
        return Api().post(path + '/uploadImg', data)
    },
    uploadIcon(data) {
        return Api().post(path + '/uploadIcon', data)
    },
    addUpdateBulk(bulkfood) {
        return Api().post(path + '/bulk', bulkfood)
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    }
}