<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">

        <v-btn fab color="primary" bottom right fixed @click="dialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <v-dialog v-model="dialog" persistent scrollable width="600">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>

              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.description" :rules="[rules.required, rules.counter]" clearable
                        :counter="250" label="Descrição"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <file-upload ref="fileUpload" :main="1" :removeOnly="true" @spliceDeleted="spliceDeleted" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text :disabled="savingInfo" @click="close">Cancelar</v-btn>
              <v-btn color="blue darken-1" text :loading="savingInfo" @click="addUpdateGroup">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card>
          <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
            <v-card-title class="headline">{{ mainTitle }}</v-card-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table :headers="headersGroups" :items="groups" hide-default-footer
              :items-per-page.sync="groups.length + 10" class="elevation-0">
              <template v-slot:item.action="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="editGroup(item)">
                      <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="deleteGroup(item)">
                      <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceMenuItems from "@/servicesLocal/ServiceMenuItems";
import FileUpload from "../common/FileUpload";
import _ from "lodash";
export default {
  name: "Menu-Item-List",
  props: ["type"],
  directives: {
    infiniteScroll
  },
  components: {
    FileUpload
  },
  data: () => ({
    mainPic: [],
    otherPics: [],
    valid: true,
    overlay: false,
    dialog: false,
    fab: false,
    headersGroups: [
      { text: "Descrição", value: "description" },
      { text: "Operações", value: "action", sortable: false, align: "center" }
    ],
    groups: [],
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    editedItem: {
      description: "",
      id: null
    },
    defaultItem: {
      description: "",
      id: null
    },
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      zero: v => v >= 0 || "Valor tem de ser >= 0"
    },
    auxImgs: [],
    imgIndex: -1
  }),
  methods: {
    editGroup(item) {
      this.editedIndex = this.groups.indexOf(item);

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImage({ path: this.editedItem.image });
      });
    },
    async getGroups() {
      try {
        this.overlay = true;
        const response = await ServiceMenuItems.getGroups(this.type);

        this.groups = response.data;

        this.overlay = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async addUpdateGroup() {
      try {

        if (this.$refs.form.validate()) {
          this.overlay = true;

          let formData = new FormData();
          formData.set("description", this.editedItem.description);
          formData.set("type", this.type);
          if (this.editedItem.id) {
            formData.set("id", this.editedItem.id);
          }

          if (this.editedItem.deleteImg) {
            formData.set("deleteImg", true);
          }

          _.each(this.$refs.fileUpload.images, img => {
            if (img.isNew === true) {
              formData.append("file", this.$refs.fileUpload.dataURItoBlob(img.path), img.name);
            }
          });

          const response = await ServiceMenuItems.addUpdateGroup(formData);

          if (this.editedIndex > -1) {
            Object.assign(this.groups[this.editedIndex], response.data);
          } else {
            this.groups.push(response.data);
          }

          this.overlay = false;

          this.$store.dispatch("setSnackbar", {});
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages([]);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteGroup(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceMenuItems.deleteGroup(item.id);
          let index = this.groups.indexOf(item);

          if (index != -1) {
            if (index == this.editGroupIndex) {
              this.clearGroup();
            }
            this.groups.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    spliceDeleted(id) {
      this.editedItem.deleteImg = true;
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Registo" : "Editar";
    },
    mainTitle() {
      let title = "";
      switch (this.type) {
        case "beverage":
          title = "Grupos Bebidas";
          break;
        case "entree":
          title = "Grupos Entradas";
          break;
        case "main":
          title = "Pratos Principais";
          break;
        case "dessert":
          title = "Grupos Sobremesas";
          break;

        default:
          title = "N/A";
          break;
      }
      return title;
    }
  },
  async mounted() {
    try {
      this.overlay = true;
      await this.getGroups(this.type);

      this.overlay = false;
    } catch (error) {
      this.overlay = false;
    }
  }
};
</script>
<style scoped>

</style>
