import Api from '@/services/Api'
const path = 'types'

export default {
    index(description, page) {
        return Api().get(path, {
            params: {
                description,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    }
}