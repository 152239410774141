import Api from '@/services/Api'
const path = 'wineries'

export default {
    index(name,description, page) {
        return Api().get(path, {
            params: {
                name,
                description,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    }
}