<template>
  <div>

    <gallery ref="gallery" :index="imgIndex" :images="auxImgs" @close="imgIndex = null"></gallery>
    <v-card height="300" v-if="main">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ title ? title : 'Imagem Principal' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$refs.inputUpload.click()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <input v-show="false" accept=".png, .jpg, .jpeg .svg" ref="inputUpload" id="inputUpload" type="file"
          @change="AddInitFile()" />
        <v-card v-show="images.length != 0" width="200" style="margin: 0 auto;">
          <v-img style="cursor: pointer !important;" :src="images[0] ? images[0].path : ''" contain
            @click="handleGalery(1)" width="200" height="150" aspect-ratio="1" class="grey lighten-2" />

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="removeOnly" color="red" text @click="removePhoto(0)">Remover</v-btn>
            <v-btn v-else color="red" text @click="deletePhoto(0)">Eliminar</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card v-else>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Imagens</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$refs.inputUploadMulti.click()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <input v-show="false" accept=".png, .jpg, .jpeg .svg" ref="inputUploadMulti" id="inputUploadMulti" type="file"
          multiple @change="AddMultiFile()" />

        <div v-show="images.length != 0" ref="picsDiv"
          style="height:250px; width: 100%;overflow-x: scroll;overflow-y: hidden;white-space: nowrap;">
          <ul class="ulPics" ref="picsUl">
            <li v-for="(c, i) in images" :key="i">
              <v-card>
                <v-img style="cursor: pointer !important;" :src="c.path" contain @click="handleGalery(i)" width="200"
                  height="150" aspect-ratio="1" class="grey lighten-2" />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="deletePhoto(i)">Eliminar</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import _ from "lodash";
import VueGallery from "vue-gallery";
import ServiceImages from "@/servicesLocal/ServiceImages";

export default {
  name: "FileUpload",
  props: ["main", "removeOnly", "title"],
  components: {
    gallery: VueGallery
  },
  data: () => ({
    name: null,
    description: null,
    images: [],
    imgIndex: -1,
    auxImgs: []
  }),
  methods: {
    AddInitFile() {
      var filesize = (
        this.$refs.inputUpload.files[0].size /
        1024 /
        1024
      ).toFixed(4); // MB

      if (filesize > 2) {
        alert("Tamanho máximo do ficheiro é 2MB");
        return;
      }

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          this.images = [];
          this.images.push({
            id: null,
            path: reader.result,
            isNew: true,
            name: this.$refs.inputUpload.files[0].name
          });
        },
        false
      );

      if (this.$refs.inputUpload.files[0]) {
        reader.readAsDataURL(this.$refs.inputUpload.files[0]);
      }
    },
    AddMultiFile() {
      _.each(this.$refs.inputUploadMulti.files, item => {
        var filesize = (item.size / 1024 / 1024).toFixed(4); // MB

        if (filesize > 2) {
          alert("Tamanho máximo do ficheiro é 2MB");
          this.images = [];
          return;
        }

        var reader = new FileReader();

        reader.addEventListener(
          "load",
          () => {
            this.images.push({
              id: null,
              path: reader.result,
              name: item.name
            });
          },
          false
        );

        if (item) {
          reader.readAsDataURL(item);
        }
      });
    },
    setExistingImages(existingImgs) {
      this.images = [];
      _.each(existingImgs, item => {
        this.images.push(item);
      });
    },
    setExistingImage(existingImg) {
      this.images = [];
      if (existingImg.path) {
        this.images.push(existingImg);
      }
    },
    handleGalery(index) {
      this.auxImgs = [];
      _.each(this.images, item => {
        this.auxImgs.push(item.path);
      });
      this.imgIndex = index;
    },
    async deletePhoto(index) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          this.overlay = true;
          if (this.images[index].id) {
            await ServiceImages.delete(this.images[index].id);
            this.$emit("spliceDeleted", this.images[index].id);
          }
          this.images.splice(index, 1);
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async removePhoto(index) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          this.$emit("spliceDeleted", 1);
          this.images.splice(index, 1);
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async uploadImgs(entityId, type) {
      let send = false;
      let formData = new FormData();
      formData.set("isMain", this.main);
      formData.set("type", type);
      formData.set("id", entityId);

      _.each(this.images, img => {
        if (img.id == null) {
          formData.append("file", this.dataURItoBlob(img.path), img.name);
          send = true;
        }
      });

      if (send) {
        let response = await ServiceImages.upload(formData);
        return response.data;
      } else {
        return [];
      }
    },
    dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    }
  }
};
</script>

<style scoped>
.ulPics {
  height: 100%;
  list-style-type: none;
  padding: 10px;
  display: -webkit-inline-box;
}

ul li {
  padding: 10px;
  height: 100%;
}

li img {
  height: 100%;
  width: 300px;
  cursor: pointer;
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
  cursor: pointer !important;
}
</style>
