import Api from '@/servicesLocal/Api'
const path = 'keys'

export default {
    index(page) {
        return Api().get(path, {
            params: {
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    },
    addUpdate(key) {
        return Api().post(path, key)
    },
    delete(id) {
        return Api().delete(path + `/${id}`)
    }
}