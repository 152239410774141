import Api from '@/servicesLocal/Api'
const path = 'types'

export default {
    index(description, page) {
        return Api().get(path, {
            params: {
                description,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/${id}`)
    },
    addUpdate(type) {
        return Api().post(path, type)
    }
}