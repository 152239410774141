<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md8>
        <v-card style="text-align : center">
          <v-toolbar dark color="red">
            <v-toolbar-title>Não tem permissão para utilizar este recurso</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>            
          <img src="@/assets/Stop.svg" alt="Infordio" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Denied",

  data: () => ({
  }),
  methods: {
  }
};
</script>
