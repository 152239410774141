<template>
  <v-container>
    <create-update ref="createUpdate" :regions="regions" :types="types" :wineries="wineries" :castes="castes"
      :overlay="overlay" @saveChanges="save" @deletePhoto="deletePhoto" />

    <v-btn fab color="green" bottom right fixed @click.stop="filterDialog = true">
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="10">
        <v-card>
          <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
            <v-card-title class="headline">Importar vinhos</v-card-title>
          </v-toolbar>
          <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="600"
            :headers="headers" :items="items" :loading="loading" hide-default-footer
            :items-per-page.sync="items.length + 10" class="elevation-1">
            
            <template v-slot:footer>
              <div style="display: grid;height: 70px;width: 100%;">
                <p v-if="loading" style="margin:auto;">A carregar...</p>
              </div>
            </template>

            <template v-slot:item.discontinued="{ item }">
              <v-icon v-if="item.discontinued" color="green darken-2">done</v-icon>
              <v-icon v-else color="red darken-2">clear</v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <td class="justify-center layout px-0">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="openCreateUpdate(item)">
                      <v-icon v-on="on" color="green">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Vizualizar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0" @click="save(item)">
                      <v-icon v-on="on" color="light-blue">mdi-database-import</v-icon>
                    </v-btn>
                  </template>
                  <span>Importar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar class="flex-grow-0" dark color="primary">
          <v-card-title>
            <span class="headline">Filtros :</span>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark rounded @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="year" label="Ano" type="number" :rules="[rules.zero]"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="regionId" :items="regions" item-text="name" item-value="id" label="Região"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="typeId" :items="types" item-text="description" item-value="id" label="Tipo"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete v-model="wineryId" :items="wineries" item-text="name" item-value="id" label="Vinicola"
                  clearable></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="cleanFilters()">Limpar</v-btn>
          <v-spacer />
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceWines from "@/services/ServiceWines";
import ServiceWinesLocal from "@/servicesLocal/ServiceWines";
import ServiceRegions from "@/services/ServiceRegions";
import ServiceTypes from "@/services/ServiceTypes";
import ServiceWineries from "@/services/ServiceWineries";
import ServiceCastes from "@/services/ServiceCastes";
import CreateUpdate from "./CreateUpdateCopy";
import _ from "lodash";
export default {
  name: "Wines-List",
  directives: {
    infiniteScroll
  },
  components: {
    CreateUpdate
  },
  data: () => ({
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Nome", value: "name" },
      { text: "Ano", value: "year" },
      { text: "Região", value: "Region.name" },
      { text: "Tipo", value: "Type.description" },
      { text: "Vinicola", value: "Winery.name" },
      { text: "Actions", value: "action", sortable: false, align: "center" }
    ],
    items: [],
    regions: [],
    types: [],
    wineries: [],
    castes: [],
    food: [],
    busy: false,
    loading: true,
    savingInfo: false,
    name: null,
    year: null,
    regionId: null,
    typeId: null,
    wineryId: null,
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      isValidInt: value =>
        /(?<=\s|^)\d+(?=\s|$)/.test(value) || "Valor inválido",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      zero: v => v >= 0 || "Valor tem de ser >= 0",
      hundred: v => v <= 100 || "Valor tem de ser <= 100"
    }
  }),
  methods: {
    cleanFilters() {
      this.name = null;
      this.year = null;
      this.regionId = null;
      this.typeId = null;
      this.wineryId = null;
      this.page = 1;
      this.getItems();
    },
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async openCreateUpdate(item) {
      try {
        if (item) {
          this.overlay = true;
          let index = this.items.indexOf(item);

          this.overlay = false;

          this.$refs.createUpdate.open(item, index);
        } else {
          this.$refs.createUpdate.open(item, -1);
        }
      } catch (error) {
        this.overlay = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceWines.index(
          this.name,
          this.year,
          this.regionId,
          this.typeId,
          this.wineryId,
          this.page
        );

        _.forEach(response.data, value => {
          _.forEach(value.Images, img => {
            img.path = process.env.VUE_APP_MAIN_API_URL + img.path;
          });

          if (value.Region.image) {
            value.Region.image = process.env.VUE_APP_MAIN_API_URL + value.Region.image;
          }

          _.forEach(value.Food, food => {
            if (food.image) {
              food.image = process.env.VUE_APP_MAIN_API_URL + food.image;
            }
            if (food.icon) {
              food.icon = process.env.VUE_APP_MAIN_API_URL + food.icon;
            }
          });
          this.items.push(value);
        });

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    async save(item, close) {
      this.overlay = true;
      try {
        let response = await ServiceWinesLocal.showByExternal(item.id);

        if (response.data) {
          if (
            !confirm(
              "Este vinho já se encontra na sua Base de Dados. Confirma a edição?"
            )
          ) {
            this.overlay = false;
            return;
          }
        }

        await ServiceWinesLocal.addUpdateImport(item);

        this.overlay = false;
        this.$store.dispatch("setSnackbar", {});

        if (close) {
          this.$refs.createUpdate.close();
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async deletePhoto(srcArray, index) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          this.overlay = true;
          if (srcArray[index].id) {
            await ServiceWines.deleteImg(srcArray[index].id);
          }
          srcArray.splice(index, 1);
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.overlay = false;
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async getRegions(page) {
      try {
        const response = await ServiceRegions.index(null, null, page);
        this.regions = this.regions.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getRegions(page);
        }
      } catch (error) {
        return;
      }
    },
    async getTypes(page) {
      try {
        const response = await ServiceTypes.index(null, page);
        this.types = this.types.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getTypes(page);
        }
      } catch (error) {
        return;
      }
    },
    async getWineries(page) {
      try {
        const response = await ServiceWineries.index(null, null, page);
        this.wineries = this.wineries.concat(response.data);

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getWineries(page);
        }
      } catch (error) {
        return;
      }
    },
    async getCastes(page) {
      try {
        const response = await ServiceCastes.index(null, null, page);
        response.data.forEach(element => {
          element.active = false;
          this.castes.push(element);
        });

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getCastes(page);
        }
      } catch (error) {
        return;
      }
    }
  },
  async mounted() {
    try {
      this.overlay = true;
      await Promise.all([
        this.getRegions(1),
        this.getTypes(1),
        this.getWineries(1),
        this.getCastes(1)
      ]);

      this.overlay = false;
    } catch (error) {
      this.overlay = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  }
};
</script>
