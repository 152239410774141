<template>
  <menu-item-groups-abs type="entree"/>
</template>

<script>
import MenuItemGroupsAbs from "./MenuItemGroupsAbs";
export default {
  name: "Entree-Groups",
  components: {
    MenuItemGroupsAbs
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
