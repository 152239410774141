<template>
  <v-app id="inspire">
    <v-navigation-drawer v-if="$route.path != '/login'" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <v-list-item :to="{
          name: 'apikeys-list'
        }">
          <v-list-item-icon>
            <v-icon>mdi-key-variant</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Chaves API</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{
          name: 'wines-list-import'
        }">
          <v-list-item-icon>
            <v-icon>mdi-database-import</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Importar Vinhos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-group prepend-icon="mdi-bottle-wine-outline" no-action :value="false">
          <v-list-item style="padding: 0px" slot="activator">
            <v-list-item-title>Os Meus Vinhos</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{
            name: 'wines-list'
          }">

            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Vinhos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{
            name: 'types-list'
          }">

            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Tipos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>

          </v-list-item>


          <v-list-item :to="{
            name: 'wineries-list'
          }">

            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Vinicolas
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>

          </v-list-item>

          <v-list-item :to="{
            name: 'regions-list'
          }">

            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Regiões
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>

          </v-list-item>

          <v-list-item :to="{
            name: 'castes-list'
          }">

            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Castas
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>

          </v-list-item>
        </v-list-group>

        <!-- <v-list-item :to="{
          name: 'foods-list'
        }">
          <v-list-item-icon>
            <v-icon>mdi-food</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Comida</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        
        <v-list-group prepend-icon="mdi-food-steak" no-action :value="false">
          <v-list-item style="padding: 0px" slot="activator">
            <v-list-item-title>Comida</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{
            name: 'maincourses-list'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Items
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{
            name: 'foods-list'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Grupos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-glass-mug-variant" no-action :value="false">
          <v-list-item style="padding: 0px" slot="activator">
            <v-list-item-title>Bebidas</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{
            name: 'beverages-list'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Items
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{
            name: 'beverages-groups'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Grupos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>


        <v-list-group prepend-icon="mdi-food" no-action :value="false">
          <v-list-item style="padding: 0px" slot="activator">
            <v-list-item-title>Entradas</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{
            name: 'entrees-list'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Items
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{
            name: 'entrees-groups'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Grupos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <!-- <v-list-item :to="{
          name: 'maincourses-list'
        }">
          <v-list-item-icon>
            <v-icon>mdi-food-steak</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pratos Principais</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <v-list-group prepend-icon="mdi-cupcake" no-action :value="false">
          <v-list-item style="padding: 0px" slot="activator">
            <v-list-item-title>Sobremesas</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{
            name: 'desserts-list'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Items
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>

          <v-list-item :to="{
            name: 'desserts-groups'
          }">
            <v-list-item-title>
              <v-icon>mdi-chevron-right</v-icon>Grupos
            </v-list-item-title>
            <v-list-item-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-item :to="{
          name: 'user'
        }">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!$store.state.isUserLoggedIn" :to="{
          name: 'login'
        }">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.state.isUserLoggedIn" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$route.path != '/login'">
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">AppyWine</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <p v-if="user" class="ma-2">{{ user.name }}</p>
      <v-btn icon large>
        <v-avatar size="32px" tile>
          <img src="@/assets/infordio.png" alt="Infordio" />
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>

      <v-snackbar v-model="snackbar.showing" :timeout="6000" :color="snackbar.color">
        {{ snackbar.text }}
        <v-btn text @click="snackbar.showing = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState(["snackbar", "user", "isUserLoggedIn"])
  },
  data: () => ({
    active: false,
    dialog: false,
    drawer: null
  }),
  methods: {
    logout() {
      try {
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setUser", null);
        this.$store.dispatch("setLogTime", null);

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error.message
        });
      }
    }
  },
  watch: {
    isUserLoggedIn(newValue) {
      if (!newValue) {
        this.$router.push({
          name: "login"
        });
      }
    }
  }, beforeCreate() {
    this.$store.dispatch("setSnackbar", {
      showing: false,
      color: "error",
      text: ""
    });
  }
};
</script>
