<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="primary" class="mb-2" @click="dialog = true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!-- <v-btn fab dark large color="primary" class="mb-2" @click="createUpdateFoodAll()">
        <v-icon>mdi-update</v-icon>
      </v-btn> -->
    </v-speed-dial>

    <v-dialog v-model="dialog" scrollable persistent>
      <v-card>
        <v-toolbar class="flex-grow-0" dark color="primary">
          <v-toolbar-title>
            <span class="headline">{{ formTitle }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark :disabled="savingInfo" @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col cols="12" lg="5">
                  <v-switch v-model="editedItem.visible" class="ma-2" label="Visivél"></v-switch>
                </v-col>
                <v-col cols="12" lg="5">
                  <v-switch v-model="editedItem.spotlight" class="ma-2" label="Destaque"></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" lg="10">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.name" :rules="[rules.required, rules.counter]" clearable
                        :counter="250" label="Name"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-autocomplete v-model="editedItem.FoodId" :items="food" item-text="name" item-value="id"
                        label="Tipo Prato" clearable :rules="[rules.required]" append-outer-icon="mdi-refresh" @click:append-outer="refreshFood(1)"></v-autocomplete>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-text-field v-model="editedItem.price" label="Preço" prefix="€" type="number"
                        :rules="[rules.isValidFloat, rules.zero]"></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-textarea v-model="editedItem.description" :rules="[rules.required]" clearable label="Descrição"
                        rows="10"></v-textarea>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" sm="12" lg="2">
                  <v-row>
                    <v-col cols="12">
                      <file-upload ref="fileUpload" :main="1" @spliceDeleted="spliceDeleted" />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row justify="center">
                <v-col cols="12">
                  <v-card>
                    <v-toolbar dark color="primary">
                      <v-toolbar-title>Recomendações de Vinhos</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <multi-selector :title="'Recomendações de Vinhos'" :fullItems="wines"
                          :existingItems="editedItem.Wines"></multi-selector>
                      </v-toolbar-items>
                    </v-toolbar>
                    <ul>
                      <li v-for="(c, i) in editedItem.Wines" :key="i">{{ c.name }}</li>
                    </ul>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <file-upload ref="fileUploadMulti" :main="0" @spliceDeleted="spliceDeleted" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text :loading="savingInfo" @click="save">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-card>
          <v-toolbar class="flex-grow-0" dark color="blue lighten-1">
            <v-card-title class="headline">Pratos Principais</v-card-title>
          </v-toolbar>
          <v-data-table v-infinite-scroll="getItems" infinite-scroll-disabled="busy" infinite-scroll-distance="600"
            :headers="headers" :items="items" :loading="loading" hide-default-footer
            :items-per-page.sync="items.length + 10" class="elevation-1">
            
            <template v-slot:footer>
              <div style="display: grid;height: 70px;width: 100%;">
                <p v-if="loading" style="margin:auto;">A carregar...</p>
              </div>
            </template>

            <template v-slot:item.visible="{ item }">
              <v-switch class="switchOverride" @change="handleQuickChange(item, true)" v-model="item.visible" label
                color="success" hide-details></v-switch>
            </template>
            <template v-slot:item.spotlight="{ item }">
              <v-switch class="switchOverride" @change="handleQuickChange(item, false)" v-model="item.spotlight" label
                color="success" hide-details></v-switch>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="editItem(item)">
                    <v-icon v-on="on" color="green">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Filtros :</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Descrição" clearable v-model="description"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filterDialog = false">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gallery ref="gallery" :index="imgIndex" :images="auxImgs" @close="imgIndex = null"></gallery>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceMainCourses from "@/servicesLocal/ServiceMainCourses";
import ServiceWines from "@/servicesLocal/ServiceWines";
import ServiceFood from "@/servicesLocal/ServiceFood";
import ServiceFoodMain from "@/services/ServiceFoods";
import VueGallery from "vue-gallery";
import FileUpload from "../common/FileUpload";
import MultiSelector from "../common/MultiSelector";
import _ from "lodash";
export default {
  name: "Main-Course-List",
  directives: {
    infiniteScroll
  },
  components: {
    FileUpload,
    MultiSelector,
    gallery: VueGallery
  },
  data: () => ({
    valid: true,
    overlay: false,
    filterDialog: false,
    dialog: false,
    fab: false,
    page: 1,
    headers: [
      { text: "Nome", value: "name", width: "20%" },
      { text: "Descrição", value: "description", width: "60%" },
      { text: "Visivél", value: "visible", width: "5%" },
      { text: "Destaque", value: "spotlight", width: "5%" },
      { text: "Operações", value: "action", sortable: false, align: "center", width: "10%" }
    ],
    items: [],
    busy: false,
    loading: true,
    savingInfo: false,
    editedIndex: -1,
    name: null,
    description: null,
    editedItem: {
      name: "",
      description: "",
      id: null,
      Wines: [],
      price: 0,
      spotlight: false,
      visible: true,
      MainCourseTypeId: null
    },
    defaultItem: {
      name: "",
      description: "",
      id: null,
      Wines: [],
      price: 0,
      spotlight: false,
      visible: true,
      MainCourseTypeId: null
    },
    dialogNewType: false,
    newType: {
      description: "",
      id: null
    },
    rules: {
      required: value => !!value || "Campo Obrigatório.",
      counter: value =>
        (value && value.length <= 250) ||
        "Campo tem de ter menos de 250 caracteres",
      isValidFloat: value =>
        /^[-+]?([0-9]+(\.[0-9]+)?|\.[0-9]+)$/.test(value) || "Valor inválido",
      zero: v => v >= 0 || "Valor tem de ser >= 0"
    },
    food: [],
    foodAll: [],
    auxImgs: [],
    imgIndex: -1,
    wines: []
  }),
  methods: {
    filter() {
      this.items = [];
      this.page = 1;

      this.getItems();
    },
    async getItems() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceMainCourses.index(
          this.name,
          this.description,
          this.page,
          50,
          null,
          null
        );

        this.items = this.items.concat(response.data);

        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }
        this.loading = false;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages(
          _.filter(item.Images, ["is_main", true])
        );
        this.$refs.fileUploadMulti.setExistingImages(
          _.filter(item.Images, ["is_main", false])
        );
      });
    },
    close() {
      this.dialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$nextTick(() => {
        this.$refs.fileUpload.setExistingImages([]);
        this.$refs.fileUploadMulti.setExistingImages([]);
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async deleteItem(item) {
      try {
        if (confirm("Confirma a eliminação deste elemento?")) {
          this.overlay = true;
          await ServiceMainCourses.delete(item.id);
          let index = this.items.indexOf(item);

          if (index != -1) {
            this.items.splice(index, 1);

            this.$store.dispatch("setSnackbar", {});
          }
          this.overlay = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.overlay = false;
      }
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.savingInfo = true;

        try {
          const response = await ServiceMainCourses.addUpdate(this.editedItem);

          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUpload.uploadImgs(response.data.id, 3)
            );
          } catch (error) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Erro ao adicionar a imagem principal"
            });
          }

          try {
            response.data.Images = response.data.Images.concat(
              await this.$refs.fileUploadMulti.uploadImgs(response.data.id, 3)
            );
          } catch (error) {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: "Erro ao adicionar a imagens"
            });
          }

          if (this.editedIndex > -1) {
            Object.assign(this.items[this.editedIndex], response.data);
          } else {
            this.items.push(response.data);
          }

          this.savingInfo = false;
          this.close();
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
          this.savingInfo = false;
        }
      }
    },
    spliceDeleted(id) {
      let index = 0;

      _.each(this.editedItem.Images, item => {
        if (item.id === id) {
          return;
        } else {
          index += 1;
        }
      });

      this.editedItem.Images.splice(index, 1);
    },
    async getWines(page) {
      try {
        const response = await ServiceWines.index(
          null,
          null,
          null,
          null,
          null,
          page,
          null,
          null,
          null
        );

        response.data.forEach(element => {
          element.active = false;
          this.wines.push(element);
        });
      } catch (error) {
        return;
      }
    },
    async refreshFood()
    {
      this.overlay = true;
      this.food = [];
      await this.getFood(1);
      this.overlay = false;

    },
    async getFood(page) {
      try {
        const response = await ServiceFood.index(null, page);
        response.data.forEach(element => {
          this.food.push(element);
        });

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getFood(page);
        }
      } catch (error) {
        return;
      }
    },
    async createUpdateFoodAll() {
      try {
        this.overlay = true;

        this.foodAll = [];
        await this.getMainFoodAll(1);
        await ServiceFood.addUpdateBulk(this.foodAll);
        this.overlay = false;
        this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.overlay = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async handleQuickChange(item, isVisible) {
      try {
        this.overlay = true;

        await ServiceMainCourses.addUpdate(item);
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        if (isVisible) {
          item.visible = !item.visible;
        } else {
          item.spotlight = !item.spotlight;
        }
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async getMainFoodAll(page) {
      try {
        const response = await ServiceFoodMain.index(null, page);
        response.data.forEach(element => {
          this.foodAll.push(element);
        });

        if (response.data.length == 0) {
          return;
        } else {
          page = page + 1;
          this.getFood(page);
        }
      } catch (error) {
        return;
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Novo Registo" : "Editar";
    }
  },
  async mounted() {
    try {
      this.overlay = true;
      await this.getFood(1);

      await this.getWines(1);

      this.overlay = false;
    } catch (error) {
      this.overlay = false;
    }
  }
};
</script>
<style scoped>
.switchOverride {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
