import Api from '@/services/Api'
const path = 'wines'

export default {
    index(name, year, region, type, winery, page) {
        return Api().get(path, {
            params: {
                name,
                year,
                region,
                type,
                winery,
                page
            }
        })
    },
    show(id) {
        return Api().get(path + `/show/${id}`);
    }
}